import React from 'react'
import '../home.css'
import Menu from './Menu';
const Contact = () => {
    const pathname = window.location.pathname
     console.log(pathname);
    return (
        <div>
             <Menu />
               <div className="Home_page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <h1>Contact Us</h1>
                            <p><b>Lorem Ipsum</b> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    )
}

export default Contact;

// {console.log("Iospopup")}
// to install the web app on your phone: open from safari the share menu<img src={Share} alt="image"/>{" "}
// than select 'Add to home screen'