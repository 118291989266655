import React from 'react'
import '../home.css'
import '../App.css';
import Menu from './Menu';
// import Share from './IosAddToHomescreen/IosImage/Share.png'
function About()
{  
    return(
        <>
        <Menu />
        <div className="App">
        <div className="Home_page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <h1>About Page</h1>
                            <p>
                                {/* <img src={Share} alt="image"/>{" "} */}
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic  typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}
export default About;

// {
//     "short_name": "React App",
//     "name": "Create React App Sample",
//     "icons": [
//       {
//         "src": "favicon.ico",
//         "sizes": "64x64 32x32 24x24 16x16",
//         "type": "image/x-icon"
//       },
//       {
//         "src": "logo192.png",
//         "type": "image/png",
//         "sizes": "192x192"
//       },
//       {
//         "src": "logo512.png",
//         "type": "image/png",
//         "sizes": "512x512"
//       }
//     ],
//     "start_url": "/index.html",
//     "scope": ".",
//     "display": "standalone",
//     "orientation": "portrait-primary",
//     "background_color": "#fff",
//     "theme_color": "#3f51b5",
//     "description": "A simple Instagram Clone, implementing a lot of PWA love.",
//     "dir": "ltr",
//     "lang": "en-US"
//   }
// "related_applications": [
//     {
//       "platform": "play",
//       "url": "https://play.google.com/store/apps/details?id=com.example.app1",
//       "id": "com.example.app1"
//     },
//     {
//       "platform": "itunes",
//       "url": "https://itunes.apple.com/app/example-app1/id123456789"
//     }
//   ]