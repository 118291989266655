import firebase from 'firebase'
 
  var firebaseConfig = {
    apiKey: "AIzaSyC6LNnfreJcaSKmR6GQhBTnbH_EyWLgfn8",
    authDomain: "first-pwa-25b08.firebaseapp.com",
    projectId: "first-pwa-25b08",
    storageBucket: "first-pwa-25b08.appspot.com",
    messagingSenderId: "41290496012",
    appId: "1:41290496012:web:9d35438804a5ca119b5629",
    measurementId: "G-L8BRVX3B05"
  };

  firebase.initializeApp(firebaseConfig);
export default firebase;