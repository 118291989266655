import React from 'react';
import '../App.css';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../home.css';
const Menu = () =>
{ const pathname = window.location.pathname
    console.log(pathname);
  return(
<Navbar bg="dark" className="main_navbar" expand="lg">
           <Navbar.Toggle aria-controls="basic-navbar-nav" />
           <Navbar.Collapse id="basic-navbar-nav">
       <Nav className="mr-auto">
           <Link  className="nav-link" to="/home1">Home</Link>
           <Link className="nav-link" to="/service">Service</Link>
           <Link className="nav-link" to="/contact">Contact</Link>
           <Link className="nav-link" to="/about">About</Link>
         </Nav>
      </Navbar.Collapse>
</Navbar>
  )
}
export default Menu;