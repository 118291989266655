
import '../App.css';
import React, { useState } from 'react';
import { useHistory} from 'react-router-dom';

const Login = () =>{
 
  
  const pathname = window.location.pathname;

  let history = useHistory();
                  
  const [state, setstate] = useState()
  const [state1, setstate1] = useState()
const handleChange = (event)=>
  {
     setstate(event.target.value)
     console.log(state)
  }
  const handleChange1 = (event)=>
  {
     setstate1(event.target.value)
     console.log(state)
  }
     const handleClick=()=>
      {
         if(state=="admin"&&state1=="123")
         {
           history.push('/home1')
          
         }
         else
         {
           return(
             alert("invalid username ans password")
           )
         }
       
      }
  
    return(
      <div className="login_wrap">
        <div className="login_content">
          <div className="logo">
            <a href="#">pwa</a>
          </div>
          <div className="form-box">
          <div className="body-form">
           <form className="login_form">
             <div className="login_head">
               <h2>Sign In</h2>
             </div>
      
              <div className="input-group mb-3">
                
   <div className="input-group-prepend">
    <span className="input-group-text"><i className="fa fa-user"></i></span>
  </div>
  <input type="text" onChange className="form-control"
   placeholder="Username"
   onChange={handleChange} 
    />
</div>
 <div className="input-group mb-3">
   <div className="input-group-prepend">
    <span className="input-group-text"><i className="fa fa-lock"></i></span>
  </div>
  <input type="password" className="form-control"  onChange={handleChange1}  placeholder="Password" />
</div>
<div > 
 
 
 </div>
 <div className="message">
  
<div className="remember_checkbox">
  <input type="checkbox"  /> Remember me
  </div>
 <div className="forgot_passwrd"><a href="#">Forgot password?</a></div>
 </div>
 <div className="login_footer">
  <button type="button" onClick={handleClick} className="button1">LOGIN</button>
  </div>
 <div className="singup_text">
    Don't have an account?<a href="">Sign Up</a> 
 </div>
   </form>
            <div className="social">
              <a href="#"><i className="fa fa-facebook"></i></a>
              <a href="#"><i className="fa fa-twitter"></i></a>
              <a href="#"><i className="fa fa-google"></i></a>
            </div>
          </div>
        </div>
        </div>
       </div> 
       
    )
    }
export default Login;