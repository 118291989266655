import React from 'react';
import PwaInstallPopupIOS from './PwaInstallPopupIOS';
import Share2 from './IosImage/Share.png'
import Icon from './IosImage/app-icon-256x256.png';
const Iospopup = ()=>
{
    return(
    <PwaInstallPopupIOS>
    <div 
appIcon={Icon}
delay={1}
style={{
 padding: '15px 30px',
 backgroundColor: 'white',
 color: 'black',
 textAlign: 'center',

 lineHeight: '1.8',

}}
force={true}
>
  

To install this Web App on your phone: Open from safari the share
menu{'  '} <img src={Share2} alt="image"/>{'  '}
than select <b>Add to HomeScreen</b>
 </div>
</PwaInstallPopupIOS>
    )
}
export default Iospopup;