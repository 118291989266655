import React, { useEffect } from "react";
import {Switch,Route} from 'react-router-dom';
import Login from './LoginPage/Login'
import Service from './Homepage/Service';
import Contact from './Homepage/Contact';
import Home1 from './Homepage/Home1';
import About from './Homepage/About';
import Iospopup  from './IosAddToHomescreen/Iospopup'; 
import firebase from './firebase'

const App = () =>
{ 
   
return(
       <>
    <div>
      <Iospopup />
        <Switch>
            <Route exact path='/' component={Login}/>
            <Route exact path="/home1" component={Home1} ></Route>
            <Route exact path="/contact" component={Contact} ></Route>
            <Route exact path="/about" component={About} ></Route>
            <Route exact path="/service" component={Service} ></Route>
       </Switch>
    </div>
    </>
   );
};
export default App;

